
import NavTabSetting from "./components/navigationTabs/NavTabSetting";

const index = () => {
  return (
    <div>
      <div className="">
        <NavTabSetting activeTab="1" />
      </div>
    </div>
  );
};

export default index;
