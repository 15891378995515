import NavTabSetting from "./components/navigationTabs/NavTabSetting";

const index = () => {
  return (
    <div className="">
      <NavTabSetting activeTab="3" />
    </div>
  );
};

export default index;
