import LIST from "../../../../components/shared/CRUD/LIST";

const ConsultantCabinet = () => {
  return (
    <div>
      <LIST idCategorie={4} idRubrique={29} />
    </div>
  );
};

export default ConsultantCabinet;
