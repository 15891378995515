import NavTabSetting from "./components/navigationTabs/NavTabSetting"

const index = () => {
    return (
        <div className="">
            <NavTabSetting activeTab="1" />
        </div>
    )

}

export default index