
import NavTabSetting from "./components/navigationTabs/NavTabSetting";


const FormAnnon = () => {
    return (
        <div className="">
            <NavTabSetting activeTab="1" />
        </div>


    )
}

export default FormAnnon