import NavTabSetting from "./components/navigationTabs/NavTabSetting"

const Admin = () => {
    return (
        <div className="">
            <NavTabSetting activeTab="1" />
        </div>
    )
}

export default Admin
